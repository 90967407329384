<template>
  <label v-show="support" ref="btn" class="uploader-btn">
    <slot></slot>
  </label>
</template>

<script>
  import { inject, nextTick, ref, onMounted } from 'vue';

  const COMPONENT_NAME = 'uploader-btn';

  export default {
    name: COMPONENT_NAME,
    props: {
      directory: {
        type: Boolean,
        default: false,
      },
      single: {
        type: Boolean,
        default: false,
      },
      attrs: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    setup(props) {
      const btn = ref(null);
      const uploader = inject('uploader');
      const { support } = uploader;
      onMounted(() => {
        nextTick(() => {
          uploader.assignBrowse(
            btn.value,
            props.directory,
            props.single,
            props.attrs
          );
        });
      });
      return {
        btn,
        support,
      };
    },
  };
</script>

<style>
  .uploader-btn {
    position: relative;
    display: inline-block;
    padding: 4px 8px;
    color: #666;
    font-size: 100%;
    line-height: 1.4;
    background: none;
    border: 1px solid #666;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
  }

  .uploader-btn:hover {
    background-color: rgb(0 0 0 / 8%);
  }
</style>

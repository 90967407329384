import axios from 'axios';
import type { RouteRecordNormalized } from 'vue-router';
import { UserState } from '@/store/modules/user/types';
// eslint-disable-next-line import/no-unresolved
import qs from 'qs';

export interface LoginData {
  tenantId?: string;
  account?: string;
  password?: string;
  type?: string;
  grantType?: string;
  source?: string;
  code?: string;
  state?: string;
}

export interface LoginRes {
  accessToken: string;
}

export function login(data: LoginData) {
  return axios.post<LoginRes>('/api/blade-auth/token', qs.stringify(data));
}

export function logout() {
  return axios.post<LoginRes>('/api/user/logout');
}

export function getUserInfo() {
  return axios.get<UserState>('/api/blade-user/currentUser');
}

export function getMenuList() {
  return axios.post<RouteRecordNormalized[]>('/api/user/menu');
}

// 我的项目
export function getMyProject() {
  return axios.get('/api/contentAudit/myProject');
}

// 最新动态
export function lastActivity(params: any) {
  return axios.get('/api/contentAudit/myProject/developments', { params });
}

export function reGetUserInfo(id: any) {
  return axios.get(`/api/blade-user/info?id=${id}`);
}

export function updateInfo(params: any) {
  return axios.post('/api/blade-user/submit', params);
}

// 乐胖胖文件上传
export function fileUpload(params: any) {
  return axios.post('https://admin.lelepen.com/api/common/upload', params);
}

<template>
  <div v-show="support" ref="drop" class="uploader-drop" :class="dropClass">
    <slot></slot>
  </div>
</template>

<script>
  import { inject, nextTick, ref, onBeforeUnmount } from 'vue';

  const COMPONENT_NAME = 'uploader-drop';

  export default {
    name: COMPONENT_NAME,
    setup() {
      const uploader = inject('uploader');
      const drop = ref(null);
      const dropClass = ref('');
      const { support } = uploader;
      const onDragEnter = () => {
        dropClass.value = 'uploader-dragover';
      };
      const onDragLeave = () => {
        dropClass.value = '';
      };
      const onDrop = () => {
        dropClass.value = 'uploader-droped';
      };
      nextTick(() => {
        const dropEle = drop.value;
        uploader.assignDrop(dropEle);
        uploader.on('dragenter', onDragEnter);
        uploader.on('dragleave', onDragLeave);
        uploader.on('drop', onDrop);
      });
      onBeforeUnmount(() => {
        const dropEle = drop.value;
        uploader.off('dragenter', onDragEnter);
        uploader.off('dragleave', onDragLeave);
        uploader.off('drop', onDrop);
        uploader.unAssignDrop(dropEle);
      });
      return {
        drop,
        dropClass,
        support,
        onDragEnter,
        onDragLeave,
        onDrop,
      };
    },
  };
</script>

<style>
  .uploader-drop {
    position: relative;
    padding: 10px;
    overflow: hidden;
    background-color: #f5f5f5;
    border: 1px dashed #ccc;
  }

  .uploader-dragover {
    background-color: #f7f7f7;
    border-color: #999;
  }
</style>

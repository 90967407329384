import { defineStore } from 'pinia';
// import projectApi from '@/api/project';
import productApi from '@/api/system/product';
import { iterationDeleteMenuChildren } from '@/utils/util';
import { TagsState, BrandProps } from './type';

const { brandList } = productApi();

// 过滤childrenList是空的数据
// const iterationDeleteMenuChildren = (arr: any[]): any[] => {
//   return arr.map((item: any) => {
//     if (item.childrenList && item.childrenList.length) {
//       item.childrenList = iterationDeleteMenuChildren(item.childrenList);
//       item.children = iterationDeleteMenuChildren(item.childrenList);
//     } else {
//       delete item.childrenList;
//     }
//     return { ...item };
//   });
// };

const useTagsStore = defineStore('tags', {
  state: (): TagsState => ({
    brandList: [],
    channelList: [],
  }),
  getters: {
    getBrandList(): BrandProps[] {
      return this.brandList;
    },
    // getChannelList(): TagProps[] {
    //   return this.channelList;
    // },
  },
  actions: {
    async getTags() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        localStorage.removeItem('tags');
        try {
          // const { 4: brandList, 7: channelList } = res.data;
          const resBrand = await brandList();
          // const resChannel = await channelList();

          this.brandList = iterationDeleteMenuChildren(resBrand.data);
          // this.channelList = resChannel.data;
          resolve(this.brandList);
        } catch (error) {
          console.log(error);
        }
      });
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'tags',
        storage: localStorage,
      },
    ],
  },
});

export default useTagsStore;

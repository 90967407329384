import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const PROJECT: AppRouteRecordRaw = {
  path: '/project',
  name: 'project',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.project',
    icon: 'icon-apps',
    requiresAuth: true,
    order: 2,
  },
  children: [
    {
      path: 'list',
      name: 'List',
      component: () => import('@/views/project/list/list.vue'),
      redirect: '/project/list/index',
      meta: {
        locale: 'menu.project.list',
        requiresAuth: true,
        roles: ['*'],
        activeMenu: 'List',
        hideInMenu: false,
      },
      children: [
        {
          path: 'index',
          name: 'ListIndex',
          component: () => import('@/views/project/list/index.vue'),
          meta: {
            locale: 'menu.project.list',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: true,
          },
        },
        {
          path: ':code',
          name: 'Detail',
          component: () => import('@/views/project/detail/index.vue'),
          meta: {
            locale: 'menu.project.detail',
            requiresAuth: true,
            roles: ['*'],
            hideInMenu: true,
          },
        },
      ],
    },
    {
      path: 'apply',
      name: 'Apply',
      component: () => import('@/views/project/apply/index.vue'),
      meta: {
        locale: 'menu.project.apply',
        requiresAuth: true,
        roles: ['产品经理'],
      },
    },
    {
      path: 'overview',
      name: 'Overview',
      component: () => import('@/views/project/overview/index.vue'),
      meta: {
        locale: 'menu.project.overview',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'files',
      name: 'Files',
      component: () => import('@/views/project/files/index.vue'),
      meta: {
        locale: 'menu.project.files',
        requiresAuth: true,
        roles: ['*'],
      },
    },
  ],
};

export default PROJECT;

export default {
  'menu.dashboard.monitor': '实时监控',
  'monitor.title.chatPanel': '聊天窗口',
  'monitor.title.quickOperation': '快捷操作',
  'monitor.title.studioInfo': '直播信息',
  'monitor.title.studioPreview': '直播预览',
  'monitor.chat.options.all': '全部',
  'monitor.chat.placeholder.searchCategory': '搜索类目',
  'monitor.chat.update': '更新',
  'monitor.list.title.order': '序号',
  'monitor.list.title.cover': '封面',
  'monitor.list.title.name': '名称',
  'monitor.list.title.duration': '视频时长',
  'monitor.list.title.id': '视频Id',
  'monitor.list.tip.rotations': '轮播次数',
  'monitor.list.tip.rest': '，节目单观众不可见',
  'monitor.list.tag.auditFailed': '审核未通过',
  'monitor.tab.title.liveMethod': '直播方式',
  'monitor.tab.title.onlinePopulation': '在线人数',
  'monitor.liveMethod.normal': '普通直播',
  'monitor.liveMethod.flowControl': '控流直播',
  'monitor.liveMethod.video': '视频直播',
  'monitor.liveMethod.web': '网页开播',
  'monitor.editCarousel': '编辑轮播',
  'monitor.startCarousel': '开始轮播',
  'monitor.quickOperation.changeClarity': '切换清晰度',
  'monitor.quickOperation.switchStream': '主备流切换',
  'monitor.quickOperation.removeClarity': '摘除清晰度',
  'monitor.quickOperation.pushFlowGasket': '推流垫片',
  'monitor.studioInfo.label.studioTitle': '直播标题',
  'monitor.studioInfo.label.onlineNotification': '上线通知',
  'monitor.studioInfo.label.studioCategory': '直播类目',
  'monitor.studioInfo.placeholder.studioTitle': '的直播间',
  'monitor.studioInfo.btn.fresh': '更新',
  'monitor.studioStatus.title.studioStatus': '直播状态',
  'monitor.studioStatus.title.pictureInfo': '画面信息',
  'monitor.studioStatus.smooth': '流畅',
  'monitor.studioStatus.frameRate': '帧率',
  'monitor.studioStatus.bitRate': '码率',
  'monitor.studioStatus.mainstream': '主流',
  'monitor.studioStatus.hotStandby': '热备',
  'monitor.studioStatus.coldStandby': '冷备',
  'monitor.studioStatus.line': '线路',
  'monitor.studioStatus.play': '播放格式',
  'monitor.studioStatus.pictureQuality': '画质',
  'monitor.studioPreview.studio': '直播间',
  'monitor.studioPreview.watching': '在看',
};

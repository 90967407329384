export default {
  'menu.user.info': '用户信息',
  'menu.system.account': '用户管理',
  'menu.system.role': '角色管理',
  'menu.system.post': '岗位管理',
  'menu.commodity.product': '产品分类',
  'menu.commodity.list': '商品列表',
  'menu.system.flow': '流程管理',
  'menu.project.list': '项目列表',
  'menu.project.detail': '项目详情',
  'menu.project.apply': '项目申请',
  'menu.project.overview': '项目进度总览',
  'menu.project.files': '项目文档',
  'userInfo.editUserInfo': '编辑信息',
  'userInfo.tab.title.overview': '总览',
  'userInfo.tab.title.project': '项目',
  'userInfo.tab.title.team': '我的团队',
  'userInfo.title.latestActivity': '最新动态',
  'userInfo.title.latestNotification': '站内通知',
  'userInfo.title.myProject': '我的项目',
  'userInfo.showMore': '查看更多',
  'userInfo.viewAll': '查看全部',
  'userInfo.nodata': '暂无数据',
  'userInfo.visits.unit': '人次',
  'userInfo.visits.lastMonth': '较上月',
};

import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { useUserStore } from '@/store';

export default function usePermission() {
  const userStore = useUserStore();
  return {
    accessRouter(route: RouteLocationNormalized | RouteRecordRaw) {
      const roleList = userStore?.roleName?.split(',');
      let union = [];
      if (route?.meta?.roles?.length) {
        union = route?.meta?.roles.filter((v) => roleList.includes(v));
      }
      return (
        !route.meta?.requiresAuth ||
        !route.meta?.roles ||
        route.meta?.roles?.includes('*') ||
        // route.meta?.roles?.includes(userStore.roleName)
        // eslint-disable-next-line prettier/prettier
        union.length
      );
    },
    findFirstPermissionRoute(_routers: any, role = 'admin') {
      const cloneRouters = [..._routers];
      while (cloneRouters.length) {
        const firstElement = cloneRouters.shift();
        if (
          // eslint-disable-next-line no-loop-func
          firstElement?.meta?.roles?.find((el: string) => {
            return el.includes('*') || role.includes(el);
          })
        )
          return { name: firstElement.name };
        if (firstElement?.children) {
          cloneRouters.push(...firstElement.children);
        }
      }
      return null;
    },
    // You can add any rules you want
  };
}

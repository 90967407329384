import type { Router, LocationQueryRaw } from 'vue-router';
import NProgress from 'nprogress'; // progress bar

import { useUserStore } from '@/store';
import { isLogin } from '@/utils/auth';

export default function setupUserLoginInfoGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    NProgress.start();
    const userStore = useUserStore();
    if (isLogin()) {
      if (userStore.role) {
        next();
      } else {
        next();
        // try {
        //   await userStore.info();
        //   next();
        // } catch (error) {
        //   await userStore.logout();
        //   next({
        //     name: 'login',
        //     query: {
        //       redirect: to.name,
        //       ...to.query,
        //     } as LocationQueryRaw,
        //   });
        // }
      }
    } else {
      if (to.name === 'login' || to.name === 'trains') {
        next();
        return;
      }
      next({
        name: 'login',
        query: {
          redirect: to.name,
          ...to.query,
        } as LocationQueryRaw,
      });
    }
  });
}

import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const SYSTEM: AppRouteRecordRaw = {
  path: '/system',
  name: 'system',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.system',
    icon: 'icon-settings',
    requiresAuth: true,
    order: 10,
    roles: ['超级管理员'],
  },
  children: [
    {
      path: 'account',
      name: 'Account',
      component: () => import('@/views/system/account/index.vue'),
      meta: {
        locale: 'menu.system.account',
        requiresAuth: true,
        roles: ['超级管理员'],
      },
    },
    {
      path: 'role',
      name: 'Role',
      component: () => import('@/views/system/role/index.vue'),
      meta: {
        locale: 'menu.system.role',
        requiresAuth: true,
        roles: ['超级管理员'],
      },
    },
    {
      path: 'post',
      name: 'Post',
      component: () => import('@/views/system/post/index.vue'),
      meta: {
        locale: 'menu.system.post',
        requiresAuth: true,
        roles: ['超级管理员'],
      },
    },
    // {
    //   path: 'product',
    //   name: 'Product',
    //   component: () => import('@/views/system/product/index.vue'),
    //   meta: {
    //     locale: 'menu.system.product',
    //     requiresAuth: true,
    //     roles: ['超级管理员'],
    //   },
    // },
    // {
    //   path: 'flow',
    //   name: 'Flow',
    //   component: () => import('@/views/system/flow/index.vue'),
    //   meta: {
    //     locale: 'menu.system.flow',
    //     requiresAuth: true,
    //     roles: ['*'],
    //   },
    // },
  ],
};

export default SYSTEM;

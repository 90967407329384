import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Message, Modal } from '@arco-design/web-vue';
import { useUserStore } from '@/store';
import { getToken } from '@/utils/auth';

export interface HttpResponse<T = unknown> {
  status: number;
  msg: string;
  code: number;
  data: T;
}

// if (import.meta.env.VITE_API_BASE_URL) {
//   axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
// }

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // let each request carry token
    // this example using the JWT token
    // Authorization is a custom headers key
    // please modify it according to the actual situation

    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = `Basic ${window.btoa('saber:saber_secret')}`;
    config.headers['Tenant-Id'] = '000000';

    let token = getToken();
    if (config?.params?.isDisk) {
      token = localStorage.getItem('netDiskToken');
    }
    if (token) {
      config.headers['blade-auth'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // do something
    return Promise.reject(error);
  }
);
// add response interceptors
axios.interceptors.response.use(
  (response: AxiosResponse<HttpResponse>) => {
    if (response.config?.params?.isDisk) {
      return response;
    }
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200 && res.code !== 2000) {
      Message.error({
        content: res.msg || 'Error',
        duration: 5 * 1000,
      });
      if (res.code === 401) {
        const userStore = useUserStore();

        userStore.logout();
        window.location.reload();
      } else if (
        // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
        [50008, 50012, 50014].includes(res.code) &&
        response.config.url !== '/api/user/info'
      ) {
        Modal.error({
          title: 'Confirm logout',
          content:
            'You have been logged out, you can cancel to stay on this page, or log in again',
          okText: 'Re-Login',
          async onOk() {
            const userStore = useUserStore();

            await userStore.logout();
            window.location.reload();
          },
        });
      }
      return Promise.reject(new Error(res.msg || 'Error'));
    }
    return res;
  },
  (error) => {
    const { data } = error.response;
    Message.error({
      content: data.msg || 'Request Error',
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

import { defineStore } from 'pinia';
// import {
//   login as userLogin,
//   logout as userLogout,
//   getUserInfo,
//   LoginData,
// } from '@/api/user';
import { login as userLogin, reGetUserInfo, LoginData } from '@/api/user';
import { setToken, clearToken } from '@/utils/auth';
import { removeRouteListener } from '@/utils/route-listener';
import { UserState } from './types';
import useAppStore from '../app';

const useUserStore = defineStore('user', {
  state: (): UserState => ({
    accessToken: undefined,
    account: undefined,
    avatar: undefined,
    deptName: undefined,
    expiresIn: undefined,
    license: undefined,
    oauthId: undefined,
    refreshToken: undefined,
    tenantId: undefined,
    tokenType: undefined,
    userId: undefined,
    userName: undefined,
    realName: undefined,
    name: undefined,
    // avatar: undefined,
    job: undefined,
    organization: undefined,
    location: undefined,
    email: undefined,
    introduction: undefined,
    personalWebsite: undefined,
    jobName: undefined,
    organizationName: undefined,
    locationName: undefined,
    phone: undefined,
    registrationDate: undefined,
    accountId: undefined,
    certification: undefined,
    postName: undefined,

    roleName: '',
    role: '',
    // userInfo: {},
  }),

  getters: {
    userInfo(state: UserState): UserState {
      return { ...state };
    },
  },

  actions: {
    switchRoles() {
      return new Promise((resolve) => {
        this.role = this.role === 'user' ? 'admin' : 'user';
        resolve(this.role);
      });
    },
    // Set user's information
    setInfo(partial: Partial<UserState>) {
      if (partial.avatar === '') {
        partial.avatar = new URL(
          '../../../assets/images/default_avatar.png',
          import.meta.url
        ).href;
      }
      this.$patch(partial);
    },

    // Reset user's information
    resetInfo() {
      this.$reset();
    },

    // Get user's information
    async info() {
      const res = await reGetUserInfo(this.userId);
      this.setInfo(res.data);
    },

    // Login
    async login(loginForm: LoginData) {
      try {
        const res = await userLogin(loginForm);
        setToken(res.data.accessToken);
        this.setInfo(res.data);
      } catch (err) {
        clearToken();
        throw err;
      }
    },
    logoutCallBack() {
      const appStore = useAppStore();
      this.resetInfo();
      clearToken();
      removeRouteListener();
      appStore.clearServerMenu();
    },
    // Logout
    async logout() {
      this.logoutCallBack();
      // try {
      //   await userLogout();
      // } finally {
      //   this.logoutCallBack();
      // }
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'user',
        storage: localStorage,
      },
    ],
  },
});

export default useUserStore;

export default {
  'menu.dashboard.workplace': '工作台',
  'workplace.welcome': '欢迎回来！',
  'workplace.balance': '余额（元）',
  'workplace.order.pending': '待支付',
  'workplace.order.pendingRenewal': '待续费订单',
  'workplace.today': '今日完成项目数',
  'workplace.week': '本周完成项目数',
  'workplace.month': '本月完成项目数',
  'workplace.year': '今年完成项目数',
  'workplace.minute': '分钟',
  'workplace.docs': '我的工作',
  'workplace.docs.productOverview': '产品概要',
  'workplace.docs.userGuide': '使用指南',
  'workplace.docs.workflow': '接入流程',
  'workplace.docs.interfaceDocs': '接口文档',
  'workplace.contentManagement': '内容管理',
  'workplace.contentStatistical': '内容分析',
  'workplace.advanced': '高级管理',
  'workplace.onlinePromotion': '线上推广',
  'workplace.contentPutIn': '内容投放',
  'workplace.announcement': '个人日程',
  'workplace.recently.visited': '最近访问',
  'workplace.record.nodata': '暂无数据',
  'workplace.quick.operation': '快捷操作',
  'workplace.quickOperation.setup': '管理',
  'workplace.allProject': '所有项目',
  'workplace.loadMore': '加载更多',
  'workplace.viewMore': '查看更多',
  'workplace.addItem': '新增事项',
  'workplace.contentData': '完成项目数据',
  'workplace.popularContent': '待办工作',
  'workplace.popularContent.text': '文本',
  'workplace.popularContent.image': '图片',
  'workplace.popularContent.video': '视频',
  'workplace.categoriesPercent': '内容类型占比',
  'workplace.pecs': '个',
};

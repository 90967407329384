import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const COMMODITY: AppRouteRecordRaw = {
  path: '/commodity',
  name: 'commodity',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.commodity',
    icon: '',
    requiresAuth: true,
    order: 9,
    roles: ['超级管理员'],
  },
  children: [
    {
      path: 'product',
      name: 'Product',
      component: () => import('@/views/commodity/product/index.vue'),
      meta: {
        locale: 'menu.commodity.product',
        requiresAuth: true,
        order: 1,
        roles: ['超级管理员'],
      },
    },
    {
      path: 'commoditylist',
      name: 'commodityList',
      component: () => import('@/views/commodity/commodityList/index.vue'),
      meta: {
        locale: 'menu.commodity.list',
        requiresAuth: true,
        order: 2,
        roles: ['超级管理员'],
      },
    },
  ],
};

export default COMMODITY;

import axios from 'axios';

export default function categoryApi() {
  return {
    // 品牌品类
    brandList() {
      return axios.get('/api/product/category/list?type=0');
    },
    // 系列
    channelList() {
      return axios.get('/api/product/category/list?type=2');
    },
  };
}

export default {
  'menu.dashboard.workplace': 'Workplace',
  'workplace.welcome': 'Welcome!',
  'workplace.balance': 'Balance (CNY)',
  'workplace.order.pending': 'Pending',
  'workplace.order.pendingRenewal': 'Renewal Order',
  'workplace.today': 'Number of tasks completed today',
  'workplace.week': 'Number of projects completed this week',
  'workplace.month': 'Number of projects completed this month',
  'workplace.year': 'Number of projects completed this year',
  'workplace.minute': 'Min',
  'workplace.docs': 'My Work',
  'workplace.docs.productOverview': 'Product Overview',
  'workplace.docs.userGuide': 'User Guide',
  'workplace.docs.workflow': 'Workflow',
  'workplace.docs.interfaceDocs': 'Interface Docs',
  //
  'workplace.contentManagement': 'Content Management',
  'workplace.contentStatistical': 'Content Statistical',
  'workplace.advanced': 'Advanced',
  'workplace.onlinePromotion': 'Online Promotion',
  'workplace.contentPutIn': 'Put In',
  'workplace.announcement': 'Personal schedule',
  'workplace.recently.visited': 'Recently Visited',
  'workplace.record.nodata': 'No data',
  'workplace.quick.operation': 'Quick Operation',
  'workplace.quickOperation.setup': 'Setup',
  'workplace.allProject': 'All',
  'workplace.loadMore': 'More',
  'workplace.viewMore': 'More',
  'workplace.addItem': 'AddItem',
  'workplace.contentData': 'Complete project data',
  'workplace.popularContent': 'To-do work',
  'workplace.popularContent.text': 'text',
  'workplace.popularContent.image': 'image',
  'workplace.popularContent.video': 'video',
  'workplace.categoriesPercent': 'Categories Percent',
  'workplace.pecs': 'pecs',
};
